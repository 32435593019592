
import moment from 'moment';
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { IOverview } from "@/core/data/overviews";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { reinitializeComponents } from "@/core/plugins/keenthemes";
import { useStore } from "vuex";

export default defineComponent({
    name: "overviews-listing",
    components: {
        Datatable,
    },
    setup() {
        const loading = ref(true);
        const store = useStore();
        const checkedOverviews = ref([]);
        const tableHeader = ref([
            {
                key: "checkbox",
                sortable: false,
            },
            {
                name: "Title",
                key: "title",
                sortable: true,
            },
            {
                name: "Content Type",
                key: "contenttype",
                sortable: true,
            },
            {
                name: "Amount of items",
                key: "itemsamount",
                sortable: true,
            },
            {
                name: "Created",
                key: "created",
                sortable: true,
            },
            {
                name: "Updated",
                key: "updated",
                sortable: true,
            },
            {
                name: "Actions",
                key: "actions",
            },
        ]);

        const tableData = ref<Array<IOverview>>([]);
        const initOverview = ref<Array<IOverview>>([]);

        const selectedCustomer = computed(() => {
            return store.getters.selectedCustomer;
        });

        const paginationHeader = ref<any>({ TotalCount: 0 });

        const pagingFilteration = ref<any>({
            paging: {
                pageNumber: 1,
                pageSize: 10,
            },
            filter: {
                searchTerm: "",
                type: "",
            },
            orderBy: {
                orderByField: "",
                orderDesc: false,
            },
        });

        const getOverviewsList = () => {
            loading.value = true;
            ApiService.post(`Overview/list`, pagingFilteration.value)
                .then(({ data, headers }) => {
                    paginationHeader.value = JSON.parse(headers["x-pagination"]);
                    tableData.value.splice(0, tableData.value.length, ...data);
                    initOverview.value.splice(0, tableData.value.length, ...data);
                    loading.value = false;
                    reinitializeComponents();
                })
                .catch(({ response }) => {
                    loading.value = false;
                    tableData.value.splice(0, tableData.value.length);
                    initOverview.value.splice(0, tableData.value.length);
                    console.log("response", response);
                });
        };

        watch(
            () => pagingFilteration.value.paging.pageSize,
            () => {
                getOverviewsList();
            },
            { deep: true }
        );

        watch(
            () => pagingFilteration.value.paging.pageNumber,
            () => {
                getOverviewsList();
            },
            { deep: true }
        );

        watch(
            () => pagingFilteration.value.orderBy,
            () => {
                getOverviewsList();
            },
            { deep: true }
        );

        const onOrderByChange = (orderQuery) => {
            pagingFilteration.value.orderBy.orderByField = orderQuery.columnName;
            pagingFilteration.value.orderBy.orderDesc = orderQuery.order === "desc";
        };

        const onItemsPerPageChange = (itemsPerPage) => {
            pagingFilteration.value.paging.pageSize = itemsPerPage;
        };

        const onCurrentChange = (currentPage) => {
            pagingFilteration.value.paging.pageNumber = currentPage;
        };

        onMounted(() => {
            setCurrentPageBreadcrumbs("Overviews", []);
            getOverviewsList();
        });

        const deleteFewOverviews = () => {
            checkedOverviews.value.forEach((item) => {
                deleteData(item);
            });
            checkedOverviews.value.length = 0;
        };

        const deleteOverview = (id) => {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-secondary",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    deleteData(id);
                }
            });
        };

        const deleteData = (id) => {
            ApiService.delete(`Overview/${id}`)
                .then(({ data }) => {
                    console.log("data", data);
                    getOverviewsList();
                    Swal.fire({
                        timer: 1500,
                        text: "Data has been successfully deleted!",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                })
                .catch(({ response }) => {
                    console.log("response", response);
                    Swal.fire({
                        text: "Sorry, looks like there are some errors detected, please try again.",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };
        const search = ref<string>("");

        const searchItems = () => {
            getOverviewsList();
        };

        return {
            loading,
            tableData,
            moment,
            tableHeader,
            deleteOverview,
            getOverviewsList,
            search,
            selectedCustomer,
            searchItems,
            checkedOverviews,
            deleteFewOverviews,
            onItemsPerPageChange,
            onCurrentChange,
            onOrderByChange,
            pagingFilteration,
            paginationHeader,
        };
    },
});
